<template>

  <div class="container" >
    <div class="title">{{ title }}</div>
    <div class="content">
      {{ content ? content : '&nbsp;' }}
    </div>
  </div>

</template>

<script>
export default {
  name: "LabelLight",
  props: {
    title: String,
    content: String,
    labelClass: String,
    condensed: Boolean,
    justify: Boolean,
  }
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  border: solid 1px var(--primary-color2);
  margin: 5px 0 5px 0;
  border-radius: 5px;
  height: auto;
}

.title {
  margin-right: 10px;
  background-color: var(--primary-color2);
  color: white;
  padding: 5px 1px 1px 10px;
  min-width: 150px;
  font-size: small;

}
.content {
  font-weight: normal;
  text-align: left;
  padding: 5px;
  border-radius: 5px;

}


</style>