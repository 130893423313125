import dotenv from 'dotenv';
import axios from "axios";

class BaseService {
    constructor() {
        // Get environment variables
        dotenv.config()
        this.axios = axios;
        // const baseProductionURL = "http://localhost:3000/api";// `${window.location.href}/api`;
        const baseProductionURL = window.location.protocol + "//" + window.location.host + "/api";
        this.axios.defaults.baseURL = (process.env.NODE_ENV === 'development') ? "http://localhost:3000/api" : baseProductionURL;
        this.axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    }
}

export default BaseService;
