import {createRouter, createWebHashHistory} from 'vue-router';
import App from '../App.vue';
import jwt from 'jsonwebtoken';
import store from '@/store'

store.getters.config

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('../components/Dashboard/Dashboard.vue')
            },
            {
                path: '/ventas-online',
                name: 'ventas-online',
                component: () => import('../components/Sales/SalesOnline.vue')
            },
            {
                path: '/tickets-ganadores',
                name: 'tickets-ganadores',
                component: () => import('../components/Tickets/Winners.vue')
            },


            // Reportes
            {
                path: '/reportes-conalot',
                name: 'reportes-conalot',
                component: () => import('../components/Reports/Conalot.vue')
            },

        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/auth/Login.vue')
    },

];

const index = createRouter({
    history: createWebHashHistory(),
    routes,
});

index.beforeEach((to, from, next) => {

    if (to.path !== '/login' && !localStorage.getItem('token')) {

        next({path: '/login'})
    } else {

        jwt.verify(localStorage.getItem('token'), 'wepa', function (err) {
            if (err) {
                localStorage.removeItem('token');
                localStorage.removeItem('vuex');
                // alert('Su sesión ha expirado.')

            }
        });

        if (!checkPermissions(to.path)) {
            localStorage.removeItem('token');
            localStorage.removeItem('vuex');
            next({path: '/login'})
        } else {

            next()
        }
    }
},)

function checkPermissions(path) {
    let response = true;
    if (store.getters.getProfile) {
        switch (Number(store.getters.getProfile.role_id)) {
            case 7: // Analista
                if (path === '/agencias' ||
                    path === '/usuarios' ||
                    path === '/listas-online' ||
                    path === '/ventas-online'
                ) {
                    response = false;
                }
                break;
            case 1:


        }
    }
    return response;
}

export default index;
