import BaseService from "./BaseService";

class UserService extends BaseService {

  async getAll(req) {
    const response = await this.axios.post('/user/all', req);
    return await response.data;
  }

  async getAllSecurity() {
    const response = await this.axios.get('/user/security');
    return await response.data;
  }

  async delete(req) {
    const response = await this.axios.post('/user/delete', req);
    return await response.data;
  }

  async create(req) {
    const response = await this.axios.post('/user/create', req);
    return await response.data;
  }

  async update(req) {
    const response = await this.axios.post('/user/update', req);
    return await response.data;
  }

  async block(req) {
    const response = await this.axios.post('/user/block', req);
    return await response.data;
  }

  async getProfile(req) {
    const response = await this.axios.post('/user/profile', req);
    return await response.data;
  }

  async getConalotProfile(req) {
    const response = await this.axios.post('/user/conalot/profile', req);
    return await response.data;
  }

  async getConalot(req) {
    const response = await this.axios.post('/user/conalot', req);
    return await response.data;
  }

}

export default UserService;
