import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})


export default new Vuex.Store({
    state: {
        user: null,
        token: null,
        isSidebarMinimized: false,
        userName: 'Usuario de prueba',
        level: 10,
        onlineSale: 0,
        comercializadores: null,
        distribuidores: null,
        bancas: null,
        agencias: null,
        sorteo: null,
        sorteosAnimalitos: null,
        tipo: null,
        lista: null,
        profile: null,
        agency: null,
        distributor: null,
        banking: null,
        block: null,
        currency: null,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        updateSidebarCollapsedState(state, isSidebarMinimized) {
            state.isSidebarMinimized = isSidebarMinimized
        },
        changeUserName(state, newUserName) {
            state.userName = newUserName
        },
        setOnlineSale(state, onlineSale) {
            state.onlineSale = onlineSale;
        },

        setComercializadores(state, comercializadores) {
            state.comercializadores = comercializadores;
        },
        setDistribuidores(state, distribuidores) {
            state.distribuidores = distribuidores;
        },
        setBancas(state, bancas) {
            state.bancas = bancas;
        },
        setAgencias(state, agencias) {
            state.agencias = agencias;
        },
        setSorteos(state, sorteos) {
            state.sorteos = sorteos;
        },
        setSorteosAnimalitos(state, sorteosAnimalitos) {
            state.sorteosAnimalitos = sorteosAnimalitos;
        },
        setTipos(state, tipos) {
            state.tipos = tipos;
        },
        setListas(state, listas) {
            state.listas = listas;
        },
        setLevel(state, level) {
            state.level = level;
        },
        setProfile(state, profile) {
            state.profile = profile;
        },
        setAgency(state, agency) {
            state.agency = agency;
        },
        setDistributor(state, distributor) {
            state.distributor = distributor;
        },
        setBanking(state, banking) {
            state.banking = banking;
        },
        setBlock(state, block) {
            state.block = block;
        },
        setCurrency(state, currency) {
            state.currency = currency;
        },
    },
    actions: {
        saveProfile(context, profile) {
            context.commit('profile', profile)
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.token;
        },
        getLevel(state) {
            return state.level;
        },
        getComercializadores(state) {
            return state.comercializadores;
        },
        getBancas(state) {
            return state.bancas;
        },
        getDistribuidores(state) {
            return state.distribuidores;
        },
        getAgencias(state) {
            return state.agencias;
        },
        getProfile(state) {
            return state.profile;
        },
        getAgency(state) {
            return state.agency;
        },
        getDistributor(state) {
            return state.distributor;
        },
        getBanking(state) {
            return state.banking;
        },
        getBlock(state) {
            return state.block;
        },
        getUser(state) {
            return state.user;
        },
        getCurrency(state) {
            return state.currency;
        }
    },
    plugins: [vuexLocal.plugin]
});

