<template>

  <Dialog :header="header"
          class="ticket-dialog"
          v-model:visible="computedVisible"
          :modal="true"
          :closable="true"
          :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '30vw'}">

    <template #header>
      <h3>Perfil de Usuario {{ getUserRoles[user.role_id] }}</h3>
    </template>

    <ProfileLabel title="Name" :content="user.name"/>
    <ProfileLabel title="Correo Electrónico" :content="user.email"/>
<!--    <ProfileLabel title="Nivel de acceso" :content="getConalotUserRoles(user.role_id)"/>-->
    <ProfileLabel title="Nivel de acceso" :content="getConalotUserRoles(user.role_id)"/>
    <ProfileLabel title="Entidad" :content="loteria && loteria[0]?loteria[0].label:null"/>
    <ProfileLabel title="Estatus" :content="getUserStatus(user.status_id)"/>
    <ProfileLabel title="Último acceso" :content="formatDateAndtime(user.lastLoginAt)"/>
    <!--    <ProfileLabel title="Fecha de creación" :content="formatDate(user.createdAt)"/>-->

  </Dialog>
</template>

<script>
import ProfileLabel from "@/components/misc/Labels/ProfileLabel";
import generalMixin from "@/mixins/generalMixin";
import UserService from "@/service/backend/UserService";
import MainService from "@/service/backend/MainService";

export default {
  name: "Profile",
  mixins: [generalMixin],
  components: {
    'ProfileLabel': ProfileLabel,
  },
  created() {
    this.userService = new UserService;
    this.mainService = new MainService;
  },
  props: {
    show: Boolean,
    header: String,
  },
  data() {
    return {
      displayTicket: false,
      particular: null,
      overlay: false,
      user: null,
      userService: null,
      loteria: null,
    }
  },
  async mounted() {
    this.user = this.$store.getters.getProfile;
    if (this.user.entity_id) {
      this.loteria = await this.mainService.getLoteria({id_loteria: this.user.entity_id});
      this.user.loteria = this.loteria[0];
      this.$store.commit('setProfile', this.user);
    }
  },
  methods: {
    closeVProfileDialog() {
      this.displayTicket = false;
      this.overlay = false;
    },
  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
    getTicketNumber() {
      return this.header;
    },
  },
}
</script>

<style scoped lang="scss">

Label2 {
  .title {
    width: 200px !important;
  }
}
</style>