<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle"/>
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menuAnalista" @menuitem-click="onMenuItemClick" v-if="identity.roleId===7"/>
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" v-if="identity.roleId!==7"/>
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view/>
      </div>
      <AppFooter/>
    </div>

    <!--    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange"/>-->
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from './app/AppTopbar.vue';
import AppMenu from './app/AppMenu.vue';
// import AppConfig from './AppConfig.vue';
import AppFooter from './app/AppFooter.vue';
import VueCookies from "vue-cookies";
import EventBus from "@/app/AppEventBus";

export default {
  created() {
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  mounted() {
    const theme = !VueCookies.get('dark') ? 'bootstrap4-light-green' : 'bootstrap4-dark-green'
    EventBus.emit('theme-change', {theme: theme, dark: null});

  },
  emits: ['change-theme'],
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: true,
      menu: [
        {
          items: [{
            label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'
          }]
        },
        {
          label: 'Sistema', icon: 'pi pi-fw pi-search',
          items: [
            {
              label: 'Ventas', icon: 'pi pi-shopping-cart',
              items: [
                {
                  label: 'Ventas en línea', icon: 'pi pi-chart-bar', to: '/ventas-online',
                },
              ]
            },
            {
              label: 'Tickets', icon: 'pi pi-ticket',
              items: [
                {
                  label: 'Ganadores', icon: 'pi pi-wallet', to: '/tickets-ganadores',
                },
                // {
                //   label: 'Vendidos', icon: 'pi pi-shopping-bag', to: '/tickets-vendidos',
                // },
              ],
            },
            {
              label: 'Reportes', icon: 'pi pi-file',
              items: [
                // {
                //   label: 'Ventas', icon: 'pi pi-file', to: '/reportes-ventas',
                // },
                {

                  label: 'Conalot', icon: 'pi pi-file', to: '/reportes-conalot',
                },
              ],
            },
          ]
        },

      ],

      identity: null,
      entidades: null,
      bugs: null,
    }
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    }
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    },
    getEntities() {
      let items = [
        {
          label: 'Agencias', icon: 'pi pi-fw pi-print', to: '/agencias',
        },
      ];
      // Distribuidores
      if (this.identity.roleId < 4 || this.identity.roleId === null) {
        items.push(
            {
              label: 'Distribuidores', icon: 'pi pi-fw pi-server', to: '/distribuidores',
            },
        );
      }
      // Bancas
      if (this.identity.roleId < 3 || this.identity.roleId === null) {
        items.push(
            {
              label: 'Bancas', icon: 'pi pi-fw pi-book', to: '/bancas',
            },
        );
      }
      // Bloques
      if (this.identity.roleId < 2) {
        items.push(
            {
              label: 'Bloques', icon: 'pi pi-fw pi-building', to: '/bloques',
            }
        );
      }
      return {
        label: 'Entidades', icon: 'pi pi-fw pi-sitemap', to: '/usuarios',
        items: items
      };
    },
    getBugs() {
      return {
        label: 'Errores',
        items: [
          {
            label: 'Sistema',  to: '/errores-sistema',
          },
          {
            label: 'Configuración', to: '/errores-configuracion',
          },
        ]
      }

    }
  },
  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    logo() {
      return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'AppConfig': AppConfig,
    'AppFooter': AppFooter,
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
