<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()"/>
      <span>Banklot</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <ul class="layout-topbar-menu hidden lg:flex origin-top">

      <button class="p-link layout-topbar-button"
              type="button"
              @click="changeTheme($event)">
        <i class="pi pi-circle-fill"></i>
      </button>

      <li>
        <button class="p-link layout-topbar-button" @click="toggle">
          <i class="pi pi-user"></i>
          <Menu id="overlay_menu" ref="menu" :model="items" :popup="true"/>
        </button>
      </li>

    </ul>
  </div>

  <Profile
      :show="displayProfile"
      @close="closeProfileDialog"/>
  />
</template>

<script>


import EventBus from "@/app/AppEventBus";
import VueCookies from 'vue-cookies'
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import Profile from "@/components/misc/Users/Profile.vue";

export default {
  components: {Profile},
  mixins: [generalMixin],

  data() {
    return {
      displayProfile: false,
      items: [
        {
          label: 'Usuario',
          items: [
            {
              label: 'Detalles',
              icon: 'pi pi-user',
              // disabled: true,
              command: () => {
                this.displayProfile = true;
              }
            },
            {
              label: 'Cerrar sesión',
              icon: 'pi pi-upload',
              command: () => {
                localStorage.removeItem('token');
                localStorage.removeItem('vuex');
                window.location.hash = "/login"
              }
            }
          ]
        }
      ],
      currencies: [
        {id: 1, code: 'VED'},
        {id: 2, code: 'USD'},
        {id: 3, code: 'COP'},
      ],
      selectedCurrency: null,

    }
  },
  created() {
    this.mainService = new MainService;
  },
  async mounted() {

  },
  methods: {
    changeTheme(event) {
      if (!VueCookies.get('dark')) {
        VueCookies.set('dark', true)
      } else {
        VueCookies.remove('dark')
      }

      const theme = !VueCookies.get('dark') ? 'bootstrap4-light-green' : 'bootstrap4-dark-green'
      EventBus.emit('theme-change', {theme: theme, dark: null});
      event.preventDefault();
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    topbarImage() {
      return this.$appState.darkTheme ? 'images/logo.png' : 'images/logo.png';
    },
    onRowSelect(event) {
      this.$store.commit('setCurrency', event.data.code);
      VueCookies.set('currency', event.data.code)
      window.location.href = '/'
    },
    closeProfileDialog() {
      this.displayProfile = false;
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  }
}
</script>
<style>
.currency-button {
  background-color: unset;
  border: solid 0;
  color: black;
  font-size: large;
}

.flag {
  width: 25px;

}

.countryDrop {
  display: flex;
  justify-content: space-between;
}

.currencyPanel {
  padding: unset;
}

.ui-datatable table thead tr {
  display: none;
}
</style>